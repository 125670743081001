body {
  background: hsl(0, 11%, 9%);
  color: #FFFFFF;
  padding: 48px;
}

.album-container {
  display:flex;
  flex-wrap: wrap;
}

.album {
  display: flex;
  flex-direction: column;
  margin: 48px 24px;
  height: 350px;
  width: 300px;
}

.album img {
  margin-bottom: 10px;
  width: 100%;
}

.artists {
  margin-top: 4px;
}
.artist-divider {
  margin-right: 3px;
}

.album a {
  color: #FFFFFF;
  text-decoration: none;
}

.album a:hover:not(.artist) {
  text-decoration: underline;
}

a.artist {
  color: #969696;
}

span.artist-divider {
  color: #969696;
}